













import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'KExpansionPanel',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    //

    return {
      //
    };
  }
});
